.modalWrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  transition: top 0.4s, opacity 0.4s;
  animation-duration: 1s;
  animation-name: animatetop;
}

.modalContent {
  transition: opacity 0.3s;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 6px 34px rgba(0, 0, 0, 0.06);
  z-index: 10;
  padding: 60px;
  border-radius: 10px;
  width: 400px;
  height: auto;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
}
@media (max-width: 600px) {
  .modalContent {
    max-width: calc(100% - 124px);
  }
}
.closeOTP {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 100;
}
.modal-title {
  position: absolute;
  top: 20px;
  right: 50%;
  transform: translateX(50%);
  font-size: 20px;
  font-weight: 600;
  color: rgb(61, 61, 61);
}
.modal-body {
  margin: 20px 0;
}

.otp-input {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}
.otp > input {
  width: 2rem !important;
  height: 2rem !important;
  margin: 0 0.7rem;
  font-size: 0.8rem;
  border-radius: 4px;
  border: 1px solid black;
  /* margin-top: 30px; */
}
.otp:focus {
  outline: none;
}

.modal-footer {
  position: absolute;
  bottom: 30px;
  right: 50%;
  transform: translateX(50%);
  padding: 10px 20px;
  border-radius: 52px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #47c3ed 0%,
    #ffffff 0.01%,
    #79d3f2 4.69%,
    #47c3ed 17.19%,
    #1291d2 100%
  );
  box-shadow: 0px 0px 3px #47c3ed;
  border: none;
  color: #fff;
}
.modal-footer:disabled {
  cursor: not-allowed;
  position: absolute;
  bottom: 30px;
  right: 50%;
  transform: translateX(50%);
  padding: 10px 20px;
  border-radius: 52px;
  background: linear-gradient(180deg, #79d3f2 4.69%, #47c3ed 17.19%);
  box-shadow: 0px 0px 3px #47c3ed;
  border: none;
  color: #fff;
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}
