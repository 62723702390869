.imageUpload {
  width: 217px;
  height: 120px;
  object-fit: cover;
  border-radius: 15px;
}
.previewProfilePic {
  width: 217px;
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 15px;
  margin-top: 10px;
  position: relative;
}
.iconUpload {
  position: absolute;
  left: 35%;
  top: 20%;
}
/* .validate {
  text-align: center;
} */
