@import url("https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600&display=swap");
body {
  margin: 0;
  font-family: "Public Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  background-image: url("/public/background.png");
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  /* display: flex;
  justify-content: center; */
}
.btn-login {
  width: 200px;
  padding: 10px;
  border-radius: 52px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #47c3ed 0%,
    #ffffff 0.01%,
    #79d3f2 4.69%,
    #47c3ed 17.19%,
    #1291d2 100%
  );
  box-shadow: 0px 0px 3px #47c3ed;
  border: none;
  margin-bottom: 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}
.btn-back {
  width: 80px;
  padding: 2px;
}
.form-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.logo {
  width: 72px;
  height: 72px;
  border-radius: 16px;
}
.title {
  color: white;
  font-size: 30px;
  line-height: 29.26px;
  font-weight: 600;
  margin: 32px 0 0 10px;
}

.inputRef {
  border: none;
  width: 100%;
  height: 40px;
  text-align: center;
  border-radius: 52px;
  font-size: 16px;
}
.inputRef:focus {
  border: none;
  outline: none;
}
input:disabled {
  color: #ffffff;
}
.gm-style-iw {
  font-size: 12px !important;
  z-index: 99999;
}
.grecaptcha-badge {
  visibility: hidden !important;
}
@media (max-width: 600px) {
  .title {
    font-size: 20px;
  }
  .form-info {
    max-width: 300px;
  }
  /* .btn-login {
    position: unset;
    margin-bottom: ;
  } */
}
