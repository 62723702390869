input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.enterprise-file-upload {
  display: flex;

  justify-content: space-between;
  margin: 20px 0;
}
.identitySelect {
  margin: 0 15px;
  background: rgba(255, 255, 255, 0.1);
  color: white;
  width: 150px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.identitySelect option {
  background: rgba(255, 255, 255, 0.1);
  color: white;
  width: 150px;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
.identitySelect option[value="1"] {
  background: rgba(255, 255, 255, 0.1);
  color: black;
}

.identitySelect option[value="2"] {
  background: rgba(255, 255, 255, 0.1);
  color: black;
}

.register-form {
  margin: 20px 0;
  color: white;
  width: 700px;
}
.form-item {
  margin: 20px 0;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
}
.form-item-direction {
  display: flex;
  flex-direction: column;
  width: 75%;
}
.form-item-direction-password {
  display: flex;
  flex-direction: column;
  width: 75%;
  position: relative;
}
.form-item-location {
  margin: 20px 0;
}
.form-item-address {
  margin: 20px 0;
  display: flex;
  align-items: center;
}
.form-item-address > input {
  width: 100%;
}
.form-item-latlong {
  margin: 20px 0;
  display: flex;
  align-items: center;
  gap: 0 30px;
}
.form-item-latlong > input {
  height: 30px;
  padding-left: 10px;
  border-radius: 8px;
  font-size: 14px;
  width: 150px;
}

.input-item {
  border: none;
  /* width: 50%; */
  height: 36px;
  padding-left: 10px;
  border-radius: 8px;
  font-size: 14px;
}
.invalid-repass {
  border: 1px solid red;
  width: 50%;
  height: 30px;
  padding-left: 10px;
  border-radius: 8px;
  font-size: 14px;
}
.invalid-repass:focus {
  outline: none;
}

.input-item:focus {
  border: none;
  outline: none;
}
.input-label {
  width: 25%;
  margin-right: 10px;
  font-size: 15px;
  margin-bottom: 5px;
}
.map-view {
  margin-top: 20px;
}
.btn-register {
  width: 200px;
  padding: 10px;
  border-radius: 52px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #47c3ed 0%,
    #ffffff 0.01%,
    #79d3f2 4.69%,
    #47c3ed 17.19%,
    #1291d2 100%
  );
  box-shadow: 0px 0px 3px #47c3ed;
  border: none;
  margin: 25px 0;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
.btn-register-container {
  text-align: center;
}
.eye-show {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 15%;
  cursor: pointer;
}
.eye-show-repass {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 15%;
  cursor: pointer;
}
@media (max-width: 800px) {
  .register-form {
    width: 600px;
  }
  /* .eye-show,
  .eye-show-repass {
    right: 130px;
  } */
}
@media (max-width: 650px) {
  .form-item-direction {
    width: 96%;
  }
  .form-item-direction-password {
    width: 96%;
  }
  .register-form {
    width: calc(100% - 20px);
    padding: 0 20px;
    /* padding-left: 200px; */
  }
  .input-label {
    width: 95%;
  }
  .form-item,
  .form-item-location {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  .form-item-address {
    width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    /* align-items: center; */
  }
  .form-item > input {
    width: 96%;
  }
  .form-item-address > input {
    width: 96%;
  }
  .form-item-latlong {
    margin: 0;
  }
  .btn-register-container {
    text-align: center;
  }
  /* .eye-show {
    right: 8px;
    top: 50%;
  }
  .eye-show-repass {
    right: 8px;
    top: 50%;
  } */
}

@media (max-width: 530px) {
  .enterprise-file-upload {
    flex-direction: column;
    align-items: center;
  }
  .form-item-latlong {
    flex-direction: column;
    gap: 10px 0;
  }
}
