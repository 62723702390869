.choose-option {
  display: flex;
  justify-content: space-between;
  width: 300px;
  margin: 30px;
  color: rgb(231, 225, 225);
  font-weight: 600;
  line-height: 22px;
}
.option {
  border-bottom: 1px solid transparent;
  transition: all 0.5s linear;
}
.option-selected {
  color: #3f86f5;
  border-bottom: 1px solid #3f86f5;
}
.option:hover {
  color: #3f86f5;
  border-bottom: 1px solid #3f86f5;
  cursor: pointer;
}

.btn-signup {
  width: 100%;
  padding: 10px;
  border-radius: 52px;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    #47c3ed 0%,
    #ffffff 0.01%,
    #79d3f2 4.69%,
    #47c3ed 17.19%,
    #1291d2 100%
  );
  box-shadow: 0px 0px 3px #47c3ed;
  border: none;
  margin-bottom: 25px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.validate {
  margin-top: 5px;
  color: rgb(251, 77, 77);
  height: 20px;
  font-weight: 600;
  font-size: 12px;
}
.refNameField {
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 700;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.848);
  font-size: 14px;
}
@media (max-width: 600px) {
  .choose-option {
    width: 100%;
    padding: 0 20px;
    margin: 30px 0;
  }
}
