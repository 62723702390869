.map {
  width: 100%;
  margin-top: 30px;
  height: calc(100vh - 140px);
  @media (max-width: 1200px) {
    margin-top: 0;
    height: calc(100vh - 80px);
  }
  // margin-left: 20px;
}
.mapUser {
  width: 100%;
  margin-top: 30px;
  height: 360px;
}

.mapRegister {
  height: 400px;
  width: 100%;
  @media (max-width: 1000px) {
    width: calc(100% - 0px);
  }
}

.mapInfo {
  position: absolute;
  z-index: 1;
  right: 50px;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  overflow-x: auto;
  padding: 5px;
  gap: 0 20px;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }

  .mapItem {
    color: rgb(26, 86, 173);
    display: flex;
    align-items: center;

    padding: 8px 14px;
    border-radius: 30px;
    background: var(--color-white);
    gap: 0 10px;
    cursor: pointer;
    min-width: max-content;
    &__active {
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.75),
          rgba(255, 255, 255, 0.75)
        ),
        #3f86f5;
      box-shadow: 0 0 4px #719ece;
    }
    @media (max-width: 500px) {
      align-items: center;
    }
  }

  @media (max-width: 1600px) {
    left: 30px;
    top: 70px;
    justify-content: flex-start;
    margin-top: 0;
  }
  @media (max-width: 700px) {
    // display: none;
    justify-content: flex-start;
    margin-top: 0px;
    gap: 5px;

    .mapItem {
      gap: 5px;
      padding: 4px 16px;
      display: inline-flex !important;
    }
  }
  @media (max-width: 500px) {
    left: 0;
    margin-top: -25px;
    right: 10px;

    .nameIcon {
      & div {
        font-size: 12px;
      }
    }
    .icon {
      width: 14px;
      height: 18px;
      margin-top: -2px;
    }
  }
}

.prefixSearch {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 30px;
  line-height: 50px;
  padding-left: 15px;
  background-color: #d9d9e0;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  cursor: pointer;
  border-left: 1px solid #d9d9e0;
  &:hover {
    box-shadow: 0 0 1px #719ece;
  }
  & span img {
    padding-top: 10px !important;
    padding-left: 13px !important;

    z-index: 10;
  }
  @media (max-width: 600px) {
    right: -70px;
  }
}

.wrapperMap {
  position: relative;
}

.pacInputView {
  position: absolute;
  top: 20px;
  left: 32px;
  z-index: 100;
  height: 43px;
  width: 400px;
  @media (max-width: 600px) {
    // left: 58px;
    top: 20px;
    width: 250px;
  }
}
.pacInput {
  height: 19px;
  width: 330px;
  border-radius: 12px;
  padding: 12px 54px 12px 16px;
  border: 1px solid var(--color-gray-2);

  &:focus {
    outline: none !important;
    box-shadow: 0 0 8px #719ece;
  }
  @media (max-width: 600px) {
    width: 250px;
  }
}

.mapSearchResult {
  padding: 10px;
  z-index: 1000;
  background: white;
  color: black;
  box-shadow: 0 0 8px #719ece;
  border-radius: 6px;
  margin-top: 2px;
}

.mapSearchResultOption {
  display: flex;
  align-items: center;
  flex: 1;
  gap: 0 5px;
  list-style: none;

  color: var(--color-gray-7);
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  & > span {
    min-width: 18px !important;
  }
  & span span img {
    object-fit: contain;
  }
  &:hover {
    text-decoration: underline;
  }
}
